<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">
 <div class="text-center " >
            <!-- <h3 style="color: #756bea;">  Total Unpaid:   </h3> -->
        </div>
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >

          <label>Entries</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <v-select
                  v-if="userData.roles[0].name == 'Master Admin'"
                  :options="isp_list"
                  placeholder="Select ISP"
                  v-model="ispIdFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                   class="invoice-filter-select"
                >
                <template #isp_list="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>

            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

    </div>
      <p>
          Total Selected Rows: &nbsp;{{ selected.length }}
          <b-button class="btn btn-secondary btn-sm m-1" @click="selectAllRows">  <span aria-hidden="true">&check;</span> All</b-button>
          <b-button class="btn btn-warning btn-sm m-1" @click="clearSelected">X Clear</b-button>
          <b-button class="btn btn-success btn-sm m-1" @click="paidSelectedInvoice">Make Paid</b-button>
          <b-button class="btn btn-info btn-sm m-1" @click="unpaidSelectedInvoice">Make Unpaid</b-button>
          <b-button class="btn btn-danger btn-sm m-1" @click="deleteSelectedInvoice">Make Delete</b-button>
      </p>
    <b-table
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      sort-icon-left
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      :select-mode="selectMode"
      selectable
      @row-selected="onRowSelected"
    >
        <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
            </template>
            <template v-else>
                <span aria-hidden="true">X</span>
                <span class="sr-only">Not selected</span>
            </template>
        </template>
      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(invoice_code)="data">
        <b-link
          :to="{ name: 'isp-invoice-preview', params: { invoiceId: data.item.id }}"
          class="font-weight-bold"
        >
          {{ data.value }}
        </b-link>
      </template>
     <!-- Column: payment_status Date -->
      <template #cell(payment_status_value)="data">
         <template v-if="data.value ===  'Paid' ">
          <b-badge
            pill
            variant="light-success"
          >
              {{ data.value }}
          </b-badge>
        </template>
        <template v-else>
           <b-badge
            pill
            variant="light-warning"
          >
            {{ data.value }}
          </b-badge>

        </template>
      </template>

      <!-- Column: Client -->
      <template #cell(user)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.user.image_path"
              :text="avatarText(data.item.user.name)"
              :variant="`light-${resolveClientAvatarVariant(data.item.user.payment_status_value)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.user.name }}
          </span>
           <!-- <small class="text-muted">{{ data.item.user.mobile }}</small> -->
           <small class="text-muted">{{ data.item.isp_user_name ? data.item.isp_user_name : null }}</small>
        </b-media>
      </template>
      <!-- Column: Balance -->
      <template #cell(grand_total)="data">
        <template>
          <b-badge   pill variant="light-success" >
              {{ data.value }}
          </b-badge>
        </template>
      </template>

      <!-- Column: invoice_date Date -->
      <template #cell(invoice_date)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>
      <!-- Column: last_payment_date Date -->
      <template #cell(last_payment_date)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>
      <!-- Column: paid_date Date -->
      <template #cell(paid_date)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

    <!-- Column: paid_date Date -->
      <template #cell(payment_method)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">


          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'isp-invoice-preview', params: { invoiceId: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useInvoicesList from './useInvoiceList'
import $ from "jquery";
import invoiceStoreModule from '../invoiceStoreModule'
import { ISP_LIST } from "@core/services/api";
import Mixin from "@core/services/mixin";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from "@/auth/jwt/useJwt";
import {checkRes} from "@core/services/helper";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
    mixins: [Mixin],

    data() {
        return {
            selectMode: 'multi',
            selected: []
        }
    },
  setup() {
    //  const isp_list = [];
    //console.log(fetchInvoices);
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
        {value: 1, label: 'Paid'},
        {value: 0, label: 'Unpaid'}
      //  'Paid',
      //  'Unpaid'
    ]
    const  userData = JSON.parse(localStorage.getItem('userData'));
    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      // unpaid_amount,
      statusFilter,
      ispIdFilter,
      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      ispIdFilter,
      // unpaid_amount,
      refetchData,

      statusOptions,
      isp_list:[],
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      userData
    }
  },
   methods:{
   idpListData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ISP_LIST)
        .then((res) => {
          console.log('invoice isp list',res.data.data);
          this.isp_list = res.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    },
   onRowSelected(items) {
       this.selected = items.map(s=>s.id);
   },
   selectAllRows() {
       this.$refs.refInvoiceListTable.selectAllRows()
   },
   clearSelected() {
       this.$refs.refInvoiceListTable.clearSelected()
   },
   paidSelectedInvoice(){
       console.log("paidSelectedInvoice");
       if (this.selected.length<=0){
           this.$toast({
               component: ToastificationContent,
               position: 'top-right',
               props: {
                   title: "Invoice Paid",
                   icon: 'CoffeeIcon',
                   variant: 'warning',
                   text: "Invoice Not Selected,before make paid select at list one invoice!!!",
               },
           })
           return true;
       }
       if (window.confirm("Are you sure you want to paid following invoice?")) {
           // Delete the item logic here
           console.log("Item deleted!");
           console.log(this.selected);
           useJwt.bulkPaidInvoice({
                   invoice_ids: this.selected ?? null,
               })
                 .then(response => {
                   if (checkRes(response.status) && response.data.data) {
                       this.$refs.refInvoiceListTable.refresh();
                       this.$router
                           .push({ name: 'isp-invoice-list' })
                           .catch(success => {
                               this.toastMessage('success', 'Invoice Payment', response)
                           })
                   } else {
                       this.$router
                           .push({ name: 'isp-invoice-list' })
                           .then(() => {
                               this.toastMessage('warning', 'Invoice Payment', response)
                           })
                           .catch(error => {
                               this.toastMessage('danger', 'Invoice Payment', response)
                           })
                   }
               })
       } else {
           console.log("Deletion cancelled.");
       }
    },
   unpaidSelectedInvoice(){
       console.log("unpaidSelectedInvoice");
       if (this.selected.length <= 0) {
           this.$toast({
               component: ToastificationContent,
               position: 'top-right',
               props: {
                   title: "Invoice Paid",
                   icon: 'CoffeeIcon',
                   variant: 'warning',
                   text: "Invoice Not Selected,before make paid select at list one invoice!!!",
               },
           })
           return true;
       }
       if (window.confirm("Are you sure you want to unpaid following invoice?")) {
           console.log("Item unpaidSelectedInvoice!");
           console.log(this.selected);
           useJwt.bulkUnpaidInvoice({
               invoice_ids: this.selected ?? null,
           }).then(response => {
                   if (checkRes(response.status) && response.data.data) {
                       this.$refs.refInvoiceListTable.refresh();
                       this.$router
                           .push({name: 'isp-invoice-list'})
                           .catch(success => {
                               this.toastMessage('success', 'Invoice', response)
                           })
                   } else {
                       this.$router
                           .push({name: 'isp-invoice-list'})
                           .then(() => {
                               this.toastMessage('warning', 'Invoice', response)
                           })
                           .catch(error => {
                               this.toastMessage('danger', 'Invoice', response)
                           })
                   }
           })
       } else {
           console.log("Deletion unpaid.");
       }
    },
   deleteSelectedInvoice(){
        console.log("deleteSelectedInvoice");
        if (this.selected.length<=0){
           this.$toast({
               component: ToastificationContent,
               position: 'top-right',
               props: {
                   title: "Invoice Paid",
                   icon: 'CoffeeIcon',
                   variant: 'warning',
                   text: "Invoice Not Selected,before make paid select at list one invoice!!!",
               },
           })
           return true;
       }
        console.log(this.selected);
           if (window.confirm("Are you sure you want to delete following invoice?")) {
           // Delete the item logic here
           console.log("Item deleted!");
           console.log(this.selected);
           useJwt.deleteInvoiceById({
                   invoice_ids: this.selected ?? null,
               })
                 .then(response => {
                   if (checkRes(response.status) && response.data.data) {
                       this.$refs.refInvoiceListTable.refresh();
                       this.$router
                           .push({ name: 'isp-invoice-list' })
                           .catch(success => {
                               this.toastMessage('success', 'Invoice', response)
                           })
                   } else {
                       console.log(response);
                       this.$router
                           .push({ name: 'isp-invoice-list' })
                           .then(() => {
                               this.toastMessage('warning', 'Invoice', response)
                           })
                           .catch(error => {
                               this.toastMessage('danger', 'Invoice', response)
                           })
                   }
               })
       } else {
           console.log("Deletion delete.");
       }
    }
  },
  mounted() {
    this.idpListData();
  },
  // mounted(){
  //    let instance = this;
  //    $("#ispListId").change(function () {
  //           this.isp_id=  $(this).children("option:selected").val();
  //           instance.fetchInvoices(null,null,this.isp_id)
  //           console.log('invoice'+ this.isp_id);
  //   });
  // }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
